<script>
import Hls from "hls.js";

export default {
  name: "CustomVideo",
  props: {
    src: {
      type: String,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    let video = this.$refs.video_player;
    let videoSrc = this.src;

    if (Hls.isSupported() && !videoSrc.includes('.youtube.com')) {
      let hls = new Hls();
      hls.loadSource(videoSrc);
      hls.attachMedia(video);
    }
  }
}
</script>

<template>
<div class="custom_video_player">
  <video v-if="!src.includes('.youtube.com')" muted controls :height="height" ref="video_player"></video>
  <iframe v-else :src="src + '?mute=1'" :height="height" width="450" frameborder="0" allowfullscreen></iframe>
</div>
</template>

<style scoped lang="scss">

</style>
import AppTimeline from "@/api/objects/AppTimeline";
import {formatDate} from "@/utils/utils";
import AppTimelinePoint from "@/api/objects/AppTimelinePoint";

export default {
    getAppsTimelines(vueContext, apps, from, to) {
        let queryArray = [];
        let appsById = {};
        apps.forEach(app => {
            appsById[app.id] = app;
        });

        apps.forEach((app) => {
            let urlQuery = '?app_id=' + app.id + '&date_from=' + formatDate(from, 'digits-dash') + '&date_to=' + formatDate(to, 'digits-dash');
            queryArray.push({
                url: process.env.VUE_APP_API_URL + 'api/applications/timelines' + urlQuery,
                method: 'GET',
            });
        });

        const hashCleaner = (data) => {
            let after = data.after;
            let before = null;
            if (data.before) {
                after = {};
                before = {};
                for (const [chank_id, after_locales_chank] of Object.entries(data.after)) {
                    after_locales_chank.data.forEach((after_locales_group, index) => {
                        if (after_locales_group.hash != data.before?.[chank_id]?.data?.[index]?.hash) {
                            if (!after[chank_id]) {
                                after[chank_id] = {
                                    data: []
                                };
                            }
                            if (!before[chank_id] && data.before?.[chank_id]?.data?.[index]) {
                                before[chank_id] = {
                                    data: []
                                };
                            }

                            after[chank_id].data[index] = after_locales_group;
                            if (data.before?.[chank_id]?.data?.[index]) {
                                before[chank_id].data[index] = data.before?.[chank_id]?.data?.[index];
                                before[chank_id].date = data.before?.[chank_id]?.date;
                            }
                        }
                    });
                }
            }

            return {
                before: before,
                after: after
            };
        }

        return new Promise((resolve, reject) => {
            vueContext.fetchRequestsBundle(queryArray).then(responses => {
                let result = [];
                responses.forEach(response => {
                    if (!response[0]?.id) {
                        return;
                    }

                    let timeline = new AppTimeline(appsById[response[0].id]);
                    let hashCleaned, before, after;
                    for (const [dateKey, date] of Object.entries(response[0].timeline)) {
                        timeline.timeline[dateKey] = {};
                        for (const [type, data] of Object.entries(date.changes)) {
                            switch (type) {
                                case 'logo':
                                    hashCleaned = hashCleaner(data);
                                    before = hashCleaned.before;
                                    after = hashCleaned.after;
                                    if (after === null || Object.keys(after).length === 0) {
                                        break;
                                    }

                                    timeline.timeline[dateKey]['icon'] =
                                        (new AppTimelinePoint(new Date(dateKey),  null, "icon", before ?? null, after));
                                    break;
                                case 'title':
                                    hashCleaned = hashCleaner(data);
                                    before = hashCleaned.before;
                                    after = hashCleaned.after;
                                    if (after === null || Object.keys(after).length === 0) {
                                        break;
                                    }

                                    timeline.timeline[dateKey]['title'] =
                                        (new AppTimelinePoint(new Date(dateKey),  null, "title", before ?? null, after));
                                    break;
                                case 'sub_title':
                                    hashCleaned = hashCleaner(data);
                                    before = hashCleaned.before;
                                    after = hashCleaned.after;
                                    if (after === null || Object.keys(after).length === 0) {
                                        break;
                                    }

                                    timeline.timeline[dateKey]['sub_title'] =
                                        (new AppTimelinePoint(new Date(dateKey),  null, "sub_title", before ?? null, after));
                                    break;
                                case 'description':
                                    hashCleaned = hashCleaner(data);
                                    before = hashCleaned.before;
                                    after = hashCleaned.after;
                                    if (after === null || Object.keys(after).length === 0) {
                                        break;
                                    }

                                    timeline.timeline[dateKey]['description'] =
                                        (new AppTimelinePoint(new Date(dateKey),  null, "description", before ?? null, after));
                                    break;
                                case 'screenshots':
                                    hashCleaned = hashCleaner(data);
                                    before = hashCleaned.before;
                                    after = hashCleaned.after;
                                    if (after === null || Object.keys(after).length === 0) {
                                        break;
                                    }

                                    timeline.timeline[dateKey]['screenshots'] =
                                        (new AppTimelinePoint(new Date(dateKey),  null, "screenshots", before ?? null, after));
                                    break;
                                case 'videos':
                                    hashCleaned = hashCleaner(data);
                                    before = hashCleaned.before;
                                    after = hashCleaned.after;
                                    if (after === null || Object.keys(after).length === 0) {
                                        break;
                                    }

                                    timeline.timeline[dateKey]['videos'] =
                                        (new AppTimelinePoint(new Date(dateKey),  null, "videos", before ?? null, after));
                                    break;
                                case 'version':
                                    timeline.timeline[dateKey]['version'] =
                                        (new AppTimelinePoint(new Date(dateKey), data.before ? new Date(data.before.date) : null, "version", data.before ? {
                                            text: data.before.data.version,
                                            note: data.before.data.note
                                        } : null, {
                                            text: data.after.data.version,
                                            note: data.after.data.note
                                        }));
                                    break;
                                case 'size':
                                    timeline.timeline[dateKey]['size'] =
                                        (new AppTimelinePoint(new Date(dateKey), data.before ? new Date(data.before.date) : null, "size", data.before ? {
                                            value_mb: data.before.data.size_mb,
                                        } : null, {
                                            value_mb: data.after.data.size_mb,
                                        }));
                                    break;
                            }
                        }
                    }

                    timeline.indexGroups();
                    result.push(timeline)
                });
                resolve(result);
            });
        });
    },
    async getAppsChangeLog(vueContext, appIds, from, to, timeLines = null) {
        if (!timeLines) {
            timeLines = await this.getAppsTimelines(vueContext, appIds, from, to);
        }

        let points = [];
        timeLines.forEach(timeline => {
            for (const [key, value] of Object.entries(timeline.timeline)) {
                for (const [type, point] of Object.entries(value)) {
                    if (type === 'groups') {
                        continue;
                    }

                    points.push({
                        app: timeline.app,
                        type: type,
                        point: point
                    });
                }
            }
        });

        //group by date and app and type
        let groupedPoints = {};
        let appById = {};
        points.forEach(point => {
            let date = formatDate(point.point.date, 'digits-dash-reverse');
            let appId = point.app.id;
            let type = point.type;
            if (!groupedPoints[date]) {
                groupedPoints[date] = {};
            }
            if (!groupedPoints[date][appId]) {
                groupedPoints[date][appId] = {};
            }
            if (!groupedPoints[date][appId][type]) {
                groupedPoints[date][appId][type] = {};
            }
            groupedPoints[date][appId][type] = point.point;
            appById[appId] = point.app;
        });

        //sort by date and make array
        let sortedPoints = [];
        Object.keys(groupedPoints).sort().reverse().forEach(date => {
            Object.keys(groupedPoints[date]).forEach(appId => {
                sortedPoints.push({
                    date: date,
                    app: appById[appId],
                    points: groupedPoints[date][appId]
                });
            });
        });

        return sortedPoints;
    }
};
<template>
  <div class="apps-timelines" v-if="timeLines">
    <order-radaso-button class="order-radaso-wrap" :pre-selected="['aso-audit']" />
    <div class="top_nav">
      <div>
        <div>
          <div class="common-btn-purple-transparent"
               @click="openCompetitorsModal"
               v-if="this.competitorsObj">Select Competitors
            ({{ this.showMyApp ? this.selectedApps.length - 1 : this.selectedApps.length }}/{{
              this.competitorsObj?.getTotalCount()
            }})
          </div>
        </div>
        <div>
          <custom-multiple-select
              :toggle-all-with-value-all="true"
              :selected-all-by-default="true"
              :options="types"
              :pre-selected="selectedTypes"
              @changed="handleSelectType"
          >
            <template #label="slotProps">
              Metadata Updates ({{ slotProps.selected.filter((item) => item !== 'all').length }})
            </template>
          </custom-multiple-select>
        </div>
        <div class="show_my_app">
          <input type="checkbox"
                 class="common-checkbox"
                 id="show_my_app_check"
                 v-model="showMyApp"
          />
          <label for="show_my_app_check">Show my App</label>
        </div>
      </div>
      <div>
        <datepicker-with-range-old
            v-if="dateFilter"
            :to-right="true"
            :date-filter="dateFilter"
            :min-date="minDate"
            :max-date="maxDate"
            :columns="2"
            :ranges="[7, 30, 90, 180]"
            @date-filter-changed="applyDatePicker"/>
      </div>
    </div>
    <div class="timeline_table">
      <div class="legend">
        <div>
          <div class="point_1"></div>
          <span>Text Metadata</span>
        </div>
        <div>
          <div class="point_2"></div>
          <span>Graphic</span>
        </div>
        <div>
          <div class="point_3"></div>
          <span>Other</span>
        </div>
      </div>
      <div class="table_block">
        <div class="table_wrap">
          <table border="0" cellpadding="0" cellspacing="0">
            <tr>
              <th rowspan="2"></th>
              <template v-for="d in dates">
                <td :colspan="d.days.length">{{ d.date }}</td>
              </template>

            </tr>
            <tr>
              <template v-for="d in dates">
                <template v-for="day in d.days">
                  <td>{{ day }}</td>
                </template>
              </template>
            </tr>

            <tr v-if="timeLines" v-for="timeline in timeLines">
              <th @click="openAppModal(timeline.app)" class="cursor-pointer">
                <custom-tooltip width="max-content">
                  <template v-slot:slotTrigger>
                    <app-image
                        :width="30"
                        :height="30"
                        :src="cropImage(timeline.app.logo, '30x30', timeline.app.store.key)"
                    />
                  </template>
                  <template v-slot:slotContent>
                    <span>{{ timeline.app.title }}</span>
                  </template>
                </custom-tooltip>

              </th>
              <template v-for="d in dates">
                <template v-for="day in d.days">
                  <td
                      class="app_timeline_td"
                      v-if='`${d.y}-${d.m}-${day}` in timeline.timeline && Object.keys(timeline.timeline[`${d.y}-${d.m}-${day}`].groups).length'
                      @click="handleOpenDateCard(`${d.y}-${d.m}-${day}`, timeline.app.id)"
                  >
                    <custom-tooltip width="max-content">
                      <template v-slot:slotTrigger>
                        <div v-if="'other' in timeline.timeline[`${d.y}-${d.m}-${day}`].groups">
                          <div style="cursor: pointer; width: 32px; height: 32px">
                            <div class="point_1"
                                 v-if="'text' in timeline.timeline[`${d.y}-${d.m}-${day}`].groups"></div>
                            <div class="point_2"
                                 v-if="'graphic' in timeline.timeline[`${d.y}-${d.m}-${day}`].groups"></div>
                            <div class="point_3"
                                 v-if="'other' in timeline.timeline[`${d.y}-${d.m}-${day}`].groups"></div>
                          </div>
                        </div>
                        <div v-else-if="'graphic' in timeline.timeline[`${d.y}-${d.m}-${day}`].groups">
                          <div style="cursor: pointer; width: 22px; height: 22px">
                            <div class="point_1"
                                 v-if="'text' in timeline.timeline[`${d.y}-${d.m}-${day}`].groups"></div>
                            <div class="point_2"
                                 v-if="'graphic' in timeline.timeline[`${d.y}-${d.m}-${day}`].groups"></div>
                            <div class="point_3"
                                 v-if="'other' in timeline.timeline[`${d.y}-${d.m}-${day}`].groups"></div>
                          </div>
                        </div>
                        <div v-else-if="'text' in timeline.timeline[`${d.y}-${d.m}-${day}`].groups">
                          <div style="cursor: pointer; width: 12px; height: 12px">
                            <div class="point_1"
                                 v-if="'text' in timeline.timeline[`${d.y}-${d.m}-${day}`].groups"></div>
                            <div class="point_2"
                                 v-if="'graphic' in timeline.timeline[`${d.y}-${d.m}-${day}`].groups"></div>
                            <div class="point_3"
                                 v-if="'other' in timeline.timeline[`${d.y}-${d.m}-${day}`].groups"></div>
                          </div>
                        </div>
                      </template>
                      <template v-slot:slotContent>
                        <div class="points_tooltip">
                          <div>
                            <span
                                v-if="timeline.timeline[`${d.y}-${d.m}-${day}`].version"><b>{{ timeline.timeline[`${d.y}-${d.m}-${day}`].version.after.text }}</b></span>
                            <span>{{ formatDate(`${d.y}-${d.m}-${day}`, 'month-day-year') }}</span>
                          </div>
                          <ul>
                            <li v-for="type in Object.keys(timeline.timeline[`${d.y}-${d.m}-${day}`])">
                              <template v-if="type !== 'groups'">
                                <div v-if="['title', 'sub_title', 'description'].includes(type)" class="point_1"></div>
                                <div v-else-if="['screenshots'].includes(type)" class="point_2"></div>
                                <div v-else-if="['size', 'version'].includes(type)" class="point_3"></div>
                                {{ type }}
                              </template>
                            </li>
                          </ul>
                        </div>
                      </template>
                    </custom-tooltip>
                  </td>
                  <td v-else></td>
                </template>
              </template>
            </tr>

          </table>
        </div>
      </div>
    </div>
    <div class="changelog_block">
      <apps-change-log v-if="changeLog" :change-log="changeLog" :min-date="this.dateFilter.start"
                       @clicked-app="openAppModal"/>
    </div>
  </div>
  <template v-else>
    <ProgressIndicator>Loading...</ProgressIndicator>
  </template>
  <competitors_select_modal
      :init-checked-count="5"
      ref="competitors_select_modal"
      :max-checked-count="15"
      :current-app="currentApp"
      :current-country="currentCountry"
      :key="currentApp.id"
      @save-and-continue="handleSelectCompetitors"
  />
  <custom-modal :opened="!competitorsModalIsOpen && selectedChangeLogCard"
                :max-width="1136"
                :scroll="true"
                :paddings="false"
                @closeModal="selectedChangeLogCard = null"
  >
    <div class="modal-message textarea-block">
      <apps-change-log-card :app-date-card="selectedChangeLogCard"/>
    </div>
  </custom-modal>
  <details-modal v-if="showAppDetailsModal"
                 :opened="showAppDetailsModal"
                 :app-data="appDetailApp"
                 :can-select-competitors="false"
                 @modal-closed="showAppDetailsModal = !showAppDetailsModal"/>
</template>

<script>
import Competitors_select_modal from "@/views/CompetitorsFinderPage/Competitors/competitors_select_modal.vue";
import {mapGetters} from "vuex";
import CustomMultipleSelect from "@/components/Forms/CustomMultipleSelect/index.vue";
import DatepickerWithRangeOld from "@/components/UI/DatepickerWithRangeOld/index.vue";
import timeline from "@/api/modules/timeline";
import {cropImage, formatDate} from "@/utils/utils";
import {storeByKey} from "@/utils/factories";
import AppImage from "@/components/UI/ImageLazyLoad/index.vue";
import AppsChangeLog from "@/views/CompetitorsFinderPage/AppsTimelines/AppsChangeLog.vue";
import App from "@/api/objects/App";
import AppLocaleInfo from "@/api/objects/AppLocaleInfo";
import ProgressIndicator from "@/components/UI/ProgressIndicator/index.vue";
import AppsChangeLogCard from "@/views/CompetitorsFinderPage/AppsTimelines/AppsChangeLogCard.vue";
import DetailsModal from "@/components/AppDetailsComponent/DetailsModal/index.vue";
import {hasBetaCookie} from "../../../utils/utils";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import OrderRadasoButton from "@/components/OrderRadaso/Button/index.vue";

export default {
  name: "AppsTimelines",
  components: {
    OrderRadasoButton, SvgIcon,
    DetailsModal,
    ProgressIndicator,
    AppsChangeLog,
    AppImage,
    DatepickerWithRangeOld,
    CustomMultipleSelect,
    AppsChangeLogCard,
    Competitors_select_modal
  },

  computed: {
    th() {
      return th
    },
    ...mapGetters([
      'currentApp',
      'currentCountry',
    ]),
    appFromCurrent() {
      let app = new App(this.currentApp.id, this.currentApp.origin_id, storeByKey(this.currentApp.store.key));
      let appInfo = new AppLocaleInfo();
      appInfo.name = this.currentApp.title;
      appInfo.logo = this.currentApp.logo;

      app.locale_info = appInfo;

      return app;
    }
  },

  data() {
    return {
      competitorsModalIsOpen: false,
      competitorsObj: null,
      selectedApps: [],
      showMyApp: true,

      minInitDate: null,
      minDate: null,
      maxDate: null,
      dateFilter: null,
      dates: [],

      timeLines: null,
      changeLog: null,
      selectedChangeLogCard: null,

      showAppDetailsModal: false,
      appDetailApp: null,

      selectedTypes: [],
      types: [
        {label: 'All', value: 'all'},
        {
          label: 'Text Metadata',
          options: [
            {label: 'Title', value: 'title'},
            {label: 'Subtitle', value: 'sub_title'},
            {label: 'Description', value: 'description'},
          ]
        },
          ...(hasBetaCookie() ? [
            {
              label: 'Graphic',
              options: [
                {label: 'Icon', value: 'icon'},
                {label: 'Screenshots', value: 'screenshots'},
                {label: 'Videos', value: 'videos'},
                // {label: 'Feature graphic', value: 'feature_graphic'},
                // {label: 'Promotional content', value: 'promotional_content'},
              ]
            }
          ]: []),
        {
          label: 'Other',
          options: [
            // {label: 'Price', value: 'price'},
            // {label: 'Features', value: 'features'},
            // {label: 'Genres', value: 'genres'},
            {label: 'Size', value: 'size'},
            {label: 'Version', value: 'version'},
          ]
        }
      ]
    }
  },

  async mounted() {
    if (this.showMyApp) {
      this.selectedApps.push(this.appFromCurrent);
    }

    this.maxDate = new Date();

    let minDate = new Date();
    minDate.setMonth(minDate.getMonth() - 6);
    this.minDate = minDate;

    let minInitDate = new Date();
    minInitDate.setMonth(minInitDate.getMonth() - 3);
    this.minInitDate = minInitDate;

    this.dateFilter = {
      start: this.minInitDate,
      end: this.maxDate,
    };
  },

  watch: {
    showMyApp(newVal, oldVal) {
      if (newVal) {
        this.selectedApps.unshift(this.appFromCurrent);
      } else {
        this.selectedApps.shift();
      }

      this.loadData();
    },
  },

  methods: {
    cropImage,
    formatDate,
    openCompetitorsModal() {
      this.competitorsModalIsOpen = true;
      this.$refs.competitors_select_modal.openModal();
    },
    openAppModal(app) {
      this.appDetailApp = app.mutateToModalAppFormat();
      this.showAppDetailsModal = true;
    },
    handleSelectCompetitors(newObj) {
      this.competitorsObj = newObj;
      let selected = this.competitorsObj.getCompetitorsCheckedOnlyInitial().map((item) => item.makeAppObj());
      if (this.showMyApp) {
        selected.unshift(this.appFromCurrent);
      }

      this.selectedApps = selected;
      this.competitorsModalIsOpen = false;

      this.loadData();
    },
    handleOpenDateCard(date, appId) {
      this.selectedChangeLogCard = this.changeLog.filter((item) => item.date === date && item.app.id === appId)[0];
    },
    handleSelectType(e) {
      this.selectedTypes = e;
      this.timeLines.map((item) => {
        for (const [date, datePoint] of Object.entries(item.timeline)) {
          for (const [prop, value] of Object.entries(datePoint)) {
            if (prop === 'groups') {
              continue;
            }

            if (!e.includes(prop)) {
              value.show = false;
            } else {
              value.show = true;
            }
          }
        }

        item.indexGroups();
      });
    },
    applyDatePicker(e) {
      this.dateFilter = {
        start: e?.start ?? this.minInitDate,
        end: e?.end ?? this.maxDate,
      };

      this.loadData();
    },
    async loadData() {
      this.timeLines = null;
      this.changeLog = null;

      this.loadDates();

      this.timeLines = await timeline.getAppsTimelines(this, this.selectedApps, this.dateFilter.start, this.dateFilter.end);
      this.changeLog = await timeline.getAppsChangeLog(this, this.selectedApps, this.dateFilter.start, this.dateFilter.end, this.timeLines);

      this.handleSelectType(this.selectedTypes);
    },
    loadDates() {
      let dates = [];
      let month = this.dateFilter.start.months;
      let days = [];
      let date = new Date(this.dateFilter.start);
      let m;
      let d;

      for (; date <= this.dateFilter.end; date.setDate(date.getDate() + 1)) {
        if (date.getMonth() !== month) {
          let setDate = new Date(date);
          setDate.setDate(setDate.getDate() - 1);

          m = (1 + setDate.getMonth()).toString();
          dates.push({
            date: formatDate(setDate, 'Month, year'),
            y: setDate.getFullYear(),
            m: m.length === 1 ? '0' + m : m,
            days: days
          });

          month = date.getMonth();
          days = [];
        }

        d = date.getDate().toString();
        if (d.length === 1) {
          d = '0' + d;
        }
        days.push(d);
      }
      if (days.length) {
        date.setDate(date.getDate() - 1);
        m = (1 + date.getMonth()).toString();
        dates.push({
          date: formatDate(date, 'Month, year'),
          y: date.getFullYear(),
          m: m.toString().length === 1 ? '0' + m : m,
          days: days
        });
      }

      this.dates = dates;
    }
  }
}
</script>

<style scoped lang="scss">
.point_1 {
  width: 12px;
  height: 12px;
  background-color: #5AAC80;
  border-radius: 50%;
  z-index: 3;
}

.point_2 {
  width: 22px;
  height: 22px;
  background-color: #B8DCCA;
  border-radius: 50%;
  z-index: 2;
}

.point_3 {
  width: 32px;
  height: 32px;
  background-color: #EBF0F8;
  border-radius: 50%;
  z-index: 1;
}

.points_tooltip {
  color: var(--neutral-800);

  .point_1, .point_2, .point_3 {
    width: 8px;
    height: 8px;
    margin-right: 5px;
  }

  > div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    :first-child {
      color: var(--neutral-800);
      margin-right: 5px;
    }

    :last-child {
      color: var(--neutral-700);
    }
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      text-transform: capitalize;
      list-style-type: none;

      display: flex;
      align-items: center;
    }
  }
}

.top_nav {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  position: sticky;
  top: 60px;
  z-index: 10;
  background-color: #f7fafd;
  padding: 15px 20px;
  margin: 0 -20px;

  .show_my_app {
    display: flex;
    align-items: center;
    user-select: none;

    label {
      cursor: pointer;
      font-weight: 400;
      color: var(--neutral-800);
      margin-left: 2px;
    }
  }

  > div {
    display: flex;

    > div {
      &:not(:first-child) {
        margin-left: 15px;
      }
    }
  }
}

.timeline_table {
  background-color: #fff;
  box-shadow: 0px 6px 12px 0px #A0BEDD3D;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 5px;

  .legend {
    display: flex;
    align-items: center;
    height: 48px;

    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: var(--neutral-800);

    > div {
      display: flex;
      align-items: center;
      margin-left: 34px;

      > span {
        margin-left: 8px;
      }
    }
  }
}

.table_block {
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    border: 1px solid var(--scroll-bar-screen);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scroll-bar-screen);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--scroll-bar-screen);
  }

  .table_wrap {
    width: max-content;
    min-width: 100%;
  }

  table {
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    color: var(--neutral-700);
    min-width: 100%;

    td {
      position: relative;

      .point_1, .point_2, .point_3 {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    td, th {
      background-color: #fff;
    }

    tr:nth-of-type(2) ~ tr {
      th, td {
        height: 55px;
        border-right: 1px solid var(--neutral-400);
        border-top: 1px solid var(--neutral-400);
        user-select: none;
      }

      td {
        width: 55px;
      }
    }

    tr:nth-of-type(3) {
      td {
        border-top: 1px solid var(--neutral-400);
      }
    }

    tr:nth-of-type(1), tr:nth-of-type(2) {
      td {
        padding: 0 15px;
        height: 30px;

        &:last-child {
          border-right: 1px solid var(--neutral-400);
        }
      }
    }

    tr:nth-of-type(2) {
      text-align: center;
    }

    th:first-child {
      position: sticky;
      left: 0;
      width: 78px;
      z-index: 4;

      border: 1px solid var(--neutral-400);
      border-bottom: none;
    }

    tr:first-of-type {
      td {
        border-top: 1px solid var(--neutral-400);
      }
    }

    tr:last-of-type {
      th, td {
        border-bottom: 1px solid var(--neutral-400);
      }
    }

    tr {
      :first-child {
        border-left: none !important;
      }

      :last-child {
        border-right: none !important;
      }
    }

  }
}

.changelog_block {
  margin: 40px 0;
}
</style>
<style>
.app_timeline_td {
  .custom-tooltip-component {
    width: max-content;
    margin: auto;
    cursor: pointer;
  }
}

.apps-timelines .order-radaso-wrap {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
}
</style>
<template>
<div class="apps_change_log">
  <template v-for="(appDateCard, index) in changeLog">
    <apps-change-log-card @open-media="handleOpenMedia" @clicked-app="(e) => this.$emit('clickedApp', e)" v-if="new Date(appDateCard.date) >= minDate" :app-date-card="appDateCard"/>
  </template>

  <vue-easy-lightbox
      :visible="visibleRef"
      :imgs="imgsRef"
      :index="indexRef"
      teleport="body"
      @hide="this.visibleRef = false"
  ></vue-easy-lightbox>
</div>
</template>

<script>

import AppImage from "@/components/UI/ImageLazyLoad/index.vue";
import AppsChangeLogCard from "@/views/CompetitorsFinderPage/AppsTimelines/AppsChangeLogCard.vue";
import {getScrollPercent} from "@/utils/utils";
import {ref} from "vue";
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  name: "AppsChangeLog",
  components: {VueEasyLightbox, AppsChangeLogCard, AppImage},

  props: {
    changeLog: {
      type: Array,
      required: true,
    },
    minDate: {
      required: true,
    },
  },

  emits: ['clickedApp'],

  data() {
    return {
      showCount: 10,

      indexRef: ref(0),
      imgsRef: ref([]),
      visibleRef: ref(false),
    }
  },

  methods: {
    handleScroll() {
      //TODO пока убрал так как трабла с оперделением сколько вообще их там показывается

      if (this.showCount >= this.changeLog.length) return;

      let percent = getScrollPercent(document);

      if (percent > 60) {
        this.showCount += 5;
      }
    },
    handleOpenMedia(e) {
      this.indexRef = e.index;
      this.imgsRef = e.urls;
      this.visibleRef = true;
    }
  },
}
</script>

<style scoped lang="scss">
.apps_change_log {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 6px 12px 0px #A0BEDD3D;
  overflow: hidden;
}
</style>
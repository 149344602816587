<template>
  <div class="app_date_card" v-show="
  'icon' in appDateCard.points && appDateCard.points.icon.show ||
  'videos' in appDateCard.points && appDateCard.points.videos.show ||
  'version' in appDateCard.points && appDateCard.points.version.show ||
  'size' in appDateCard.points && appDateCard.points.size.show ||
  'title' in appDateCard.points && appDateCard.points.title.show ||
  'sub_title' in appDateCard.points && appDateCard.points.sub_title.show ||
  'description' in appDateCard.points && appDateCard.points.description.show ||
  'screenshots' in appDateCard.points && appDateCard.points.screenshots.show">
    <div class="top_block">
      <div>
        <div class="logo cursor-pointer" @click="this.$emit('clickedApp', appDateCard.app)">
          <app-image
              :width="50"
              :height="50"
              :src="cropImage(appDateCard.app.logo, '50x50', appDateCard.app.store.key)"
          />
        </div>
        <div class="title_block">
          <div class="title">
            <a :href="appDateCard.app.storeLink" target="_blank">
              <svg-icon v-once icon="link-icon" class="link-icon common-app-store-link"/>
            </a>
            <span @click="this.$emit('clickedApp', appDateCard.app)" class="cursor-pointer">{{ appDateCard.app.title }}</span>
          </div>
          <div class="version_block">
            <template v-if="'version' in appDateCard.points && appDateCard.points.version.before">
              <span>Version <b>{{appDateCard.points.version.before.text}}</b> / </span>
              <span>{{formatDate(appDateCard.points.version.prevDate, 'month-day-year')}}</span>
              <span class="arrow_right"><svg-icon icon="arrow-left"/></span>
            </template>
            <span v-if="'version' in appDateCard.points">Version <b>{{appDateCard.points.version.after.text}}</b> / </span>
            <span>{{formatDate(appDateCard.date, 'month-day-year')}}</span>
          </div>
        </div>
      </div>
      <div>
        <div class="before"><span></span>Before</div>
        <div class="after"><span></span>After</div>
      </div>
    </div>
    <div class="top_change_separator"></div>
    <div class="changes_block">
      <table class="w100" cellspacing="0" cellpadding="0">
        <template v-if="'version' in appDateCard.points && appDateCard.points.version.show">
          <template v-if="appDateCard.points.version.after.note">
            <tr>
              <th colspan="2" class="w100 line_title">Release notes</th>
            </tr>
            <tr class="tr_changes">
              <td colspan="2" class="w100 br-none" v-html="appDateCard.points.version.after.note"></td>
            </tr>
          </template>
          <tr>
            <th colspan="2" class="w100 line_title">Version</th>
          </tr>
          <tr class="tr_changes">
            <td class="w50">{{ appDateCard.points.version.before?.text ?? '-' }}</td>
            <td class="w50">{{ appDateCard.points.version.after.text }}</td>
          </tr>
        </template>
        <template v-if="'size' in appDateCard.points && appDateCard.points.size.show">
          <tr>
            <th colspan="2" class="w100 line_title">Size</th>
          </tr>
          <tr class="tr_changes">
            <td class="w50">{{ appDateCard.points.size.before?.value_mb ?? '-' }} MB</td>
            <td class="w50">
              <div class="size_after">
                <span>{{ appDateCard.points.size.after.value_mb }} MB</span>
                <span v-if="appDateCard.points.size.before && appDateCard.points.size.after.value_mb > appDateCard.points.size.before.value_mb"
                      class="hint green">+{{
                    (appDateCard.points.size.after.value_mb - appDateCard.points.size.before.value_mb).toFixed(2)
                  }}MB</span>
                <span v-else-if="appDateCard.points.size.before" class="hint red">{{
                    (appDateCard.points.size.after.value_mb - appDateCard.points.size.before.value_mb).toFixed(2)
                  }}MB</span>
              </div>
            </td>
          </tr>
        </template>

        <template v-if="'icon' in appDateCard.points && appDateCard.points.icon.show">

          <template v-for="(after_locales_chank, chank_id) in appDateCard.points.icon.after">
            <template v-for="(after_locales_group, index) in after_locales_chank.data">
              <tr>
                <th colspan="2" class="w100 line_title">Icon <span class="locales_hint">{{Object.values(after_locales_group.locales).join(', ')}}</span>
                  <span class="locales_hint" v-if="appDateCard.points.icon.before?.[chank_id]">- {{formatDate(appDateCard.points.icon.before?.[chank_id]?.date, 'month-day-year')}}</span>
                </th>
              </tr>
              <tr class="tr_changes">
                <td class="w50">
                  <div class="logo_cont">
                    <img v-if="appDateCard.points.icon.before?.[chank_id]?.data?.[index]?.url" :src="cropImage(appDateCard.points.icon.before?.[chank_id]?.data?.[index]?.url, '125x125', appDateCard.app.store.key)">
                  </div>
                </td>
                <td class="w50">
                  <div class="logo_cont">
                    <img :src="cropImage(after_locales_group.url, '125x125', appDateCard.app.store.key)">
                  </div>
                </td>
              </tr>
            </template>
          </template>

        </template>

        <template v-if="'title' in appDateCard.points && appDateCard.points.title.show">

          <template v-for="(after_locales_chank, chank_id) in appDateCard.points.title.after">
            <template v-for="(after_locales_group, index) in after_locales_chank.data">
              <tr>
                <th colspan="2" class="w100 line_title">Title <span class="locales_hint">{{Object.values(after_locales_group.locales).join(', ')}}</span>
                  <span class="locales_hint" v-if="appDateCard.points.title.before?.[chank_id]">- {{formatDate(appDateCard.points.title.before?.[chank_id]?.date, 'month-day-year')}}</span>
                </th>
              </tr>
              <tr class="tr_changes"
                  v-html="diffTexts(appDateCard.points.title.before?.[chank_id]?.data?.[index]?.text ?? '', after_locales_group.text)">
              </tr>
            </template>
          </template>

        </template>
        <template v-if="'sub_title' in appDateCard.points && appDateCard.points.sub_title.show">

          <template v-for="(after_locales_chank, chank_id) in appDateCard.points.sub_title.after">
            <template v-for="(after_locales_group, index) in after_locales_chank.data">
              <tr>
                <th colspan="2" class="w100 line_title">Subtitle <span class="locales_hint">{{Object.values(after_locales_group.locales).join(', ')}}</span>
                  <span class="locales_hint" v-if="appDateCard.points.sub_title.before?.[chank_id]">- {{formatDate(appDateCard.points.sub_title.before?.[chank_id]?.date, 'month-day-year')}}</span>
                </th>
              </tr>
              <tr class="tr_changes"
                  v-html="diffTexts(appDateCard.points.sub_title.before?.[chank_id]?.data?.[index]?.text ?? '', after_locales_group.text)">
              </tr>
            </template>
          </template>

        </template>
        <template v-if="'description' in appDateCard.points && appDateCard.points.description.show">

          <template v-for="(after_locales_chank, chank_id) in appDateCard.points.description.after">
            <template v-for="(after_locales_group, index) in after_locales_chank.data">
              <tr>
                <th colspan="2" class="w100 line_title">Description <span class="locales_hint">{{Object.values(after_locales_group.locales).join(', ')}}</span>
                  <span class="locales_hint" v-if="appDateCard.points.description.before?.[chank_id]">- {{formatDate(appDateCard.points.description.before?.[chank_id]?.date, 'month-day-year')}}</span>
                </th>
              </tr>
              <template v-if="appDateCard.points.description.before?.[chank_id]?.data?.[index]">
                <tr>
                  <td class="w50 change_sub_title">Lost words</td>
                  <td class="w50 change_sub_title">New words</td>
                </tr>
                <tr class="tr_changes"
                    v-html="diffDescWords(appDateCard.points.description.before?.[chank_id]?.data?.[index]?.text ?? '', after_locales_group.text)">
                </tr>
              </template>
              <tr class="tr_changes"
                  v-html="diffTexts(appDateCard.points.description.before?.[chank_id]?.data?.[index]?.text ?? '', after_locales_group.text)">
              </tr>
            </template>
          </template>

        </template>
        <template v-if="'screenshots' in appDateCard.points && appDateCard.points.screenshots.show">

          <template v-for="(after_locales_chank, chank_id) in appDateCard.points.screenshots.after">
            <template v-for="(after_locales_group, index) in after_locales_chank.data">
              <template v-if="after_locales_group">
                <tr>
                  <th colspan="2" class="w100 line_title">Screenshots <span class="locales_hint">{{Object.values(after_locales_group.locales).join(', ')}}</span>
                    <span class="locales_hint" v-if="appDateCard.points.screenshots.before?.[chank_id]">- {{formatDate(appDateCard.points.screenshots.before?.[chank_id]?.date, 'month-day-year')}}</span>
                  </th>
                </tr>

                <template v-for="(after_urls, device) in after_locales_group.urls">
                  <tr class="tr_changes">
                    <td class="w50">
                      <span class="locales_hint" v-if="appDateCard.app.store.key !== 'GOOGLE_PLAY'">{{ device }}</span>
                      <div class="screenshots">
                        <div class="cursor-pointer" v-for="(url, urlI) in appDateCard.points.screenshots.before?.[chank_id]?.data?.[index]?.urls[device] ?? []" @click="showSingle(appDateCard.points.screenshots.before?.[chank_id]?.data?.[index]?.urls[device] ?? [], urlI)">
                          <img :src="'https://' + cropImageToHeight(url, 500, appDateCard.app.store.key)">
                        </div>
                      </div>
                    </td>
                    <td class="w50">
                      <span class="locales_hint" v-if="appDateCard.app.store.key !== 'GOOGLE_PLAY'">{{ device }}</span>
                      <div class="screenshots">
                        <div class="cursor-pointer" v-for="(url, urlI) in after_urls" @click="showSingle(after_urls, urlI)">
                          <img :src="'https://' + cropImageToHeight(url, 500, appDateCard.app.store.key)">
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>

              </template>
            </template>
          </template>

        </template>

        <template v-if="'videos' in appDateCard.points && appDateCard.points.videos.show">

          <template v-for="(after_locales_chank, chank_id) in appDateCard.points.videos.after">
            <template v-for="(after_locales_group, index) in after_locales_chank.data">
              <template v-if="after_locales_group">
                <tr>
                  <th colspan="2" class="w100 line_title">Videos <span class="locales_hint">{{Object.values(after_locales_group.locales).join(', ')}}</span>
                    <span class="locales_hint" v-if="appDateCard.points.videos.before?.[chank_id]">- {{formatDate(appDateCard.points.videos.before?.[chank_id]?.date, 'month-day-year')}}</span>
                  </th>
                </tr>

                <template v-for="(after_urls, device) in after_locales_group.urls">
                  <tr class="tr_changes">
                    <td class="w50">
                      <span class="locales_hint" v-if="appDateCard.app.store.key !== 'GOOGLE_PLAY'">{{ device }}</span>
                      <div class="screenshots">
                        <div class="cursor-pointer" v-for="(url, urlI) in appDateCard.points.videos.before?.[chank_id]?.data?.[index]?.urls[device] ?? []">
                          <custom-video :height="250" :src="url"/>
                        </div>
                      </div>
                    </td>
                    <td class="w50">
                      <span class="locales_hint" v-if="appDateCard.app.store.key !== 'GOOGLE_PLAY'">{{ device }}</span>
                      <div class="screenshots">
                        <div class="cursor-pointer" v-for="(url, urlI) in after_urls">
                          <custom-video :height="250" :src="url"/>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>

              </template>
            </template>
          </template>

        </template>

      </table>
    </div>
  </div>
</template>

<script>
import AppImage from "@/components/UI/ImageLazyLoad/index.vue";
import {formatDate, cropImage, cropImageToHeight} from "@/utils/utils";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import {diffWords} from "diff";
import CustomVideo from "@/components/UI/CustomVideo/CustomVideo.vue";

export default {
  name: "AppsChangeLogCard",
  components: {CustomVideo, SvgIcon, AppImage},

  props: {
    appDateCard: {
      type: Object,
      required: true,
    },
  },

  emits: ['clickedApp', 'openMedia'],

  methods: {
    formatDate,
    cropImage,
    cropImageToHeight,
    showSingle(urls, index, addProtocol = true) {
      if (typeof urls === 'object') urls = Object.values(urls);

      this.$emit('openMedia', {
        urls: urls.map(item => {
          return {
            src: addProtocol ? 'https://' + item : item,
          }
        }),
        index: Number(index),
      });
    },
    diffTexts(before, after) {
      //TODO slowly method

      let beforeWithRemoved = '<td class="w50">';
      let afterWithAdded = '<td class="w50">';

      if (!before) {
        beforeWithRemoved += '—</td>';
        afterWithAdded + after + '</td>';

        return beforeWithRemoved + afterWithAdded;
      }

      diffWords(before, after).forEach((part) => {
        part.value = part.value.replace(/[\n\r]/g, '<br>');
        if (part.added) {
          afterWithAdded += `<span class="added">${part.value}</span>`;
        } else if (part.removed) {
          beforeWithRemoved += `<span class="removed">${part.value}</span>`;
        } else {
          beforeWithRemoved += part.value;
          afterWithAdded += part.value;
        }
      });

      if (!before) {
        beforeWithRemoved += '-';
      }
      if (!after) {
        afterWithAdded += '-';
      }

      beforeWithRemoved += '</td>';
      afterWithAdded += '</td>'

      return  beforeWithRemoved + afterWithAdded;
    },
    diffDescWords(before, after) {
      let beforeWithRemoved = '<td class="w50 removed">';
      let afterWithAdded = '<td class="w50 added">';

      let beforeWords = new Set();
      let afterWords = new Set();

      const patternComa = new RegExp(`^[^\\p{L}\\p{N}]+|[^\\p{L}\\p{N}]+$`, 'ug');
      const patternSpaces = new RegExp(`\\s+|\\\\n`, 'g');

      diffWords(before, after).forEach((part) => {
        if (!part.value) {
          return;
        }
        if (part.added) {
          part.value.split(patternSpaces).forEach((word) => afterWords.add(word.trim().replace(patternComa, '').toLowerCase()));
        } else if (part.removed) {
          part.value.split(patternSpaces).forEach((word) => beforeWords.add(word.trim().replace(patternComa, '').toLowerCase()));
        }
      });

      beforeWords = Array.from(beforeWords);
      afterWords = Array.from(afterWords);

      const pattern = new RegExp(`[^\\p{L}\\p{N}]`, 'ugi');

      beforeWords = beforeWords.filter(x => x && x.replace(pattern, '').length > 1);
      afterWords = afterWords.filter(x => x && x.replace(pattern, '').length > 1);

      let differenceBefore = beforeWords.filter(x => x && !afterWords.includes(x));
      let differenceAfter = afterWords.filter(x => x && !beforeWords.includes(x));

      differenceBefore.forEach((word) => {
        beforeWithRemoved += `${word}, `;
      });
      differenceAfter.forEach((word) => {
        afterWithAdded += `${word}, `;
      });

      if (differenceBefore.length) {
        beforeWithRemoved = beforeWithRemoved.slice(0, -2);
      }
      if (differenceAfter.length) {
        afterWithAdded = afterWithAdded.slice(0, -2);
      }

      beforeWithRemoved += '</td>';
      afterWithAdded += '</td>'

      return beforeWithRemoved.trim() + afterWithAdded.trim();
    },
  },
}
</script>


<style scoped lang="scss">
.app_date_card {
  color: var(--neutral-800);
  font-size: 16px;
  font-weight: 400;

  .w100 {
    width: 100%;
  }

  .br-none {
    border-right: none !important;
  }

  .arrow_right svg {
    transform: rotate(180deg);
  }

  .size_after {
    display: flex;
    align-items: center;

    .hint {
      margin-left: 5px;
    }
  }

  .top_block {
    display: flex;
    justify-content: space-between;
    padding: 24px;
    color: var(--neutral-800);

    > div:first-child {
      display: flex;
      align-items: center;

      .logo {
        box-shadow: 0 0 6px #A0BEDD3D;
      }

      .title_block {
        margin-left: 15px;
        text-align: left;

        .title {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          color: var(--neutral-900);

          a {
            margin-right: 5px;
          }
        }
      }
    }

    > div:last-child {
      display: flex;
      align-items: flex-end;

      .before, .after {
        display: flex;
        align-items: center;

        span {
          width: 40px;
          height: 10px;
          margin-right: 5px;
        }
      }

      .before {
        margin-right: 15px;

        span {
          background: rgba(236, 92, 62, 0.24);
        }
      }

      .after {
        span {
          background: rgba(90, 172, 128, 0.24);
        }
      }
    }
  }

  .top_change_separator {
    height: 10px;
    width: 100%;
    background: linear-gradient(90deg, rgba(236, 92, 62, 0.24) 50%, rgba(90, 172, 128, 0.24) 50%);
  }

  .version_block {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: var(--neutral-800);

    .arrow_right {
      margin: 0 5px;
    }
  }

  .line_title {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0em;
    color: var(--neutral-900);
  }

  .locales_hint {
    color: var(--neutral-700);
    font-weight: 400;
    margin-left: 5px;
  }

  .screenshots {
    overflow-x: scroll;
    display: flex;
    min-height: 269px;

    > div {
      object-fit: cover;
      width: auto;
      height: auto;
      border-radius: 8px;
      margin-right: 10px;
      margin-bottom: 10px;
    }

    img {
      border-radius: 8px;
      height: 250px;
      user-select: none;

      &:last-child {
        margin-right: 0;
      }
    }

    &::-webkit-scrollbar {
      height: 4px;
    }
    &::-webkit-scrollbar-track {
      border: 1px solid var(--scroll-bar-screen);
    }
    &::-webkit-scrollbar-thumb {
      background: var(--scroll-bar-screen);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: var(--scroll-bar-screen);
    }
  }
}
</style>
<style lang="scss">
.app_date_card {
  table {
    .w50 {
      width: 50%;
      max-width: 1px; //screenshots horisontal scroll hack
    }

    .removed {
      color: rgba(236, 92, 62, 1);
    }

    .added {
      color: rgba(90, 172, 128, 1);
    }

    .hint {
      font-size: 12px;
    }

    tr {
      &.tr_changes {
        td {
          vertical-align: top;
        }
      }

      td, th {
        border-bottom: 1px solid var(--neutral-400);
        text-align: left;

        padding: 9px 24px;
      }

      td:first-child {
        border-right: 1px solid var(--neutral-400);
      }

      td.change_sub_title {
        border-bottom: none;
        color: var(--neutral-800);
        padding-bottom: 0;
      }
    }
  }
}
</style>